import { getRating } from '@/helpers/statusIncident.js';
import { supportRequests } from '@/helpers/dummyData';
import TicketItem from '@/components/request/elements/TicketItem/TicketItem.vue';
import { mapActions, mapState } from 'vuex';
import { showError, hasItems } from '@/helpers/globalHelpers';

export default {
	name: 'RequestList',
	data() {
		return {
			loading: false,
			searchTimeout: null,
			searchValue: '',
			dialog: false,
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Atención de Tickets',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			statusList: [
				{ text: 'SOLICITADO', value: 'SOLICITADO' },
				{ text: 'CANCELADO', value: 'CANCELADO' },
				{ text: 'RESOLUCION', value: 'RESOLUCION' },
				{ text: 'CONSULTA', value: 'CONSULTA' },
				{ text: 'CERRADO', value: 'CERRADO' },
				{ text: 'CALIFICADO', value: 'CALIFICADO' },
			],
			// areaList: [
			// 	{ text: 'TI', id: 2 },
			// 	{ text: 'Legal', id: 1 },
			// 	{ text: 'Finanzas', id: 4 },
			// ],
			ticketfilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				num_ticket: null,
				status: null,
				generic: false,
				//q: '',
			},
			// filters: {
			// 	q: null,
			// 	page: 1,
			// 	area: null,
			// 	status: null,
			// 	pagination: true,
			// 	per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			// },
			supportRequests: supportRequests,
		};
	},

	created() {
		this.getTickets();
		// this.getUsersActive();
		this.getResponsibles();
	},

	methods: {
		// ...mapActions('security', ['getUsersActive']),
		...mapActions('user',['getResponsibles']),

		...mapActions('ticket', ['listTicketForResponsibles', 'cleanTickets']),
		getRating,
		hasItems,

		searchTickets() {
			this.ticketfilters.page = 1;
			this.getTickets();
		},

		async getTickets() {
			this.loading = true;
			const { error } = await this.listTicketForResponsibles(this.ticketfilters);
			if (error) showError(error);
			this.loading = false;
		},
		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.ticketfilters.num_ticket = this.searchValue;
				this.getTickets();
			}, 800);
		},
	},

	beforeDestroy() {
		this.cleanTickets();
	},
	destroyed() {
		clearTimeout(this.timeout);
	},

	components: {
		TicketItem,
	},

	computed: {
		...mapState('ticket', [
			'tickets',
			'ticketsPagination',
			'loadingTicket',
			'filtersTicket',
		]),
		hasReturnListPermission() {
			return this.$store.getters['auth/hasActivity'](
				'devoluciones',
				'listaDevoluciones',
				'listarDevoluciones'
			);
		},
	},
};
