<template>
	<request-list />
</template>
<script>
import RequestList from '@/components/request/RequestList/RequestList.vue';

export default {
	name: 'RequestListPage',
	components: { RequestList },
};
</script>
